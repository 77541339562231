jQuery(document).ready(function ($) {
    console.log('test');
    $(".sevice-link").click(function () {
        sessionStorage.setItem("manufature", "0");
        // window.location.href = 'http://protech-automotive-hybrid-co-uk.stackstaging.com/services/'
    });
    $(".Manufacturer-link").click(function () {
        sessionStorage.setItem("manufature", "1");
        // window.location.href = 'http://protech-automotive-hybrid-co-uk.stackstaging.com/services/'
    });
    test = sessionStorage.getItem("manufature");

    if (sessionStorage.getItem("manufature") == 1) {
        $(".services-button").addClass("inverse");
        $(".manufature-button").removeClass("inverse");
        $('#main-services-section-part').css({
            "position": "absolute",
            "top": "0",
            "right": "100vw",
        });
        $('#manufaturer').css({
            "top": "0",
            "left": "0",
            "position": "relative",
        });
    }

    $('.manufature-button').click(function () {
        $(".services-button").addClass("inverse");
        $(".manufature-button").removeClass("inverse");
        $('#main-services-section-part').css({
            "position": "absolute",
            "top": "0",
            "right": "100vw",
        });
        $('#manufaturer').css({
            "top": "0",
            "left": "0",
            // "width": "100%"
        });
        setTimeout(function () {
            $('#manufaturer').css({
                "position": "relative",
                // "width": "unset"
            });
        }, 300);
    });
    $('.services-button').click(function () {
        $(".services-button").removeClass("inverse");
        $(".manufature-button").addClass("inverse");
        $('#manufaturer').css({
            "position": "absolute",
            "top": "0",
            "left": "100vw",
        });
        $('#main-services-section-part').css({
            "top": "0",
            "right": "0",
            // "width": "100%"
        });
        setTimeout(function () {
            $('#main-services-section-part').css({
                "position": "relative",
                // "width": "unset"
            });
        }, 300);
    });
    $('.one').css('opacity', '1');
    setTimeout(() => {
        $('.one').css({
            'opacity': '1',
            'position': 'relative',
            'right': 'unset',
            'z-index': '2',

        });
    }, 500);
    $('.next1').click(function () {
        $("html, body").animate({
            scrollTop: 500
        }, "slow");
        $('.one').css('opacity', '0');
        setTimeout(() => {
            $('.one').css({
                'position': 'absolute',
                'right': '24%',
                'opacity': '0',
                'z-index': '1',
            });
        }, 500);
        $('.two').css('opacity', '1');
        setTimeout(() => {
            $('.two').css({
                'opacity': '1',
                'position': 'relative',
                'right': 'unset',
                'z-index': '2',
            });
        }, 500);
    });
    $('.previous2').click(function () {
        $("html, body").animate({
            scrollTop: 500
        }, "slow");
        $('.two').css('opacity', '0');
        setTimeout(() => {
            $('.two').css({
                'position': 'absolute',
                'right': '24%',
                'opacity': '0',
                'z-index': '1',
            });
        }, 500);
        $('.one').css('opacity', '1');
        setTimeout(() => {
            $('.one').css({
                'opacity': '1',
                'position': 'relative',
                'right': 'unset',
                'z-index': '2',
            });
        }, 500);
    });
    $('.next2').click(function () {
        $("html, body").animate({
            scrollTop: 500
        }, "slow");
        $('.two').css('opacity', '0');
        setTimeout(() => {
            $('.two').css({
                'position': 'absolute',
                'right': '24%',
                'opacity': '0',
                'z-index': '1',
            });
        }, 500);
        $('.three').css('opacity', '1');
        setTimeout(() => {
            $('.three').css({
                'opacity': '1',
                'position': 'relative',
                'right': 'unset',
                'z-index': '2',
            });
        }, 500);
    });
    $('.previous3').click(function () {
        $("html, body").animate({
            scrollTop: 500
        }, "slow");
        $('.three').css('opacity', '0');
        setTimeout(() => {
            $('.three').css({
                'position': 'absolute',
                'right': '24%',
                'opacity': '0',
                'z-index': '1',
            });
        }, 500);
        $('.two').css('opacity', '1');
        setTimeout(() => {
            $('.two').css({
                'opacity': '1',
                'position': 'relative',
                'right': 'unset',
                'z-index': '2',
            });
        }, 500);
    });
    $('.next3').click(function () {
        $("html, body").animate({
            scrollTop: 500
        }, "slow");
        $('.three').css('opacity', '0');
        setTimeout(() => {
            $('.three').css({
                'position': 'absolute',
                'right': '24%',
                'opacity': '0',
                'z-index': '1',
            });
        }, 500);
        $('.four').css('opacity', '1');
        setTimeout(() => {
            $('.four').css({
                'opacity': '1',
                'position': 'relative',
                'right': 'unset',
                'z-index': '2',

            });
        }, 500);
    });
    $('.previous4').click(function () {
        $("html, body").animate({
            scrollTop: 500
        }, "slow");
        $('.four').css('opacity', '0');
        setTimeout(() => {
            $('.four').css({
                'position': 'absolute',
                'right': '24%',
                'opacity': '0',
                'z-index': '1',
            });
        }, 500);
        $('.three').css('opacity', '1');
        setTimeout(() => {
            $('.three').css({
                'opacity': '1',
                'position': 'relative',
                'right': 'unset',
                'z-index': '2',

            });
        }, 500);
    });
    $('.next4').click(function () {
        $("html, body").animate({
            scrollTop: 500
        }, "slow");
        $('.four').css('opacity', '0');
        setTimeout(() => {
            $('.four').css({
                'position': 'absolute',
                'right': '24%',
                'opacity': '0',
                'z-index': '1',
            });
        }, 500);
        $('.five').css('opacity', '1');
        setTimeout(() => {
            $('.five').css({
                'opacity': '1',
                'position': 'relative',
                'right': 'unset',
                'z-index': '2',

            });
        }, 500);
    });
    $('.previous5').click(function () {
        $("html, body").animate({
            scrollTop: 500
        }, "slow");
        $('.five').css('opacity', '0');
        setTimeout(() => {
            $('.five').css({
                'position': 'absolute',
                'right': '24%',
                'opacity': '0',
                'z-index': '1',
            });
        }, 500);
        $('.four').css('opacity', '1');
        setTimeout(() => {
            $('.four').css({
                'opacity': '1',
                'position': 'relative',
                'right': 'unset',
                'z-index': '2',

            });
        }, 500);
    });
    $('.next5').click(function () {
        $("html, body").animate({
            scrollTop: 500
        }, "slow");
        $('.five').css('opacity', '0');
        setTimeout(() => {
            $('.five').css({
                'position': 'absolute',
                'right': '24%',
                'opacity': '0',
                'z-index': '1',
            });
        }, 500);
        $('.six').css('opacity', '1');
        setTimeout(() => {
            $('.six').css({
                'opacity': '1',
                'position': 'relative',
                'right': 'unset',
                'z-index': '2',

            });
        }, 500);
    });
    $('.previous6').click(function () {
        $("html, body").animate({
            scrollTop: 500
        }, "slow");

        $('.six').css('opacity', '0');
        setTimeout(() => {
            $('.six').css({
                'position': 'absolute',
                'right': '24%',
                'opacity': '0',
                'z-index': '1',
            });
        }, 500);
        $('.five').css('opacity', '1');
        setTimeout(() => {
            $('.five').css({
                'opacity': '1',
                'position': 'relative',
                'right': 'unset',
                'z-index': '2',

            });
        }, 500);
    });
});